.bottomfixd2,
body {
    background-color: #fff
}

.gt__organizer-sec .gt__form-block,
a,
ul {
    padding: 0
}

.bottomfixd2,
.cRQwSB,
.organizername,
.thanktexts,
.txt-center {
    text-align: center
}

.gt__call-sec .gt__number-sec img,
.hrZxnn {
    vertical-align: middle
}

.gjGbrl,
.gt__events-content p,
svg:not(:root) {
    overflow: hidden
}
.bottomfixd.proceedbtnsec2{
    height: 105px;
}
.ShowCase,
li {
    list-style: none
}

* {
    box-sizing: border-box
}

body {
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    letter-spacing: .5px
}

img {
    max-width: 100%
}
.gt__signin-sec1 {
    padding: 130px 30px 70px;
}

.bottomfixd2 {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    height: 71px;
    width: 100%
}

.bottomfixd.gt__user-sec2 {
    padding: 14px 30px
}

.withoutdescount {
    text-decoration: line-through;
    display: inline-block !important
}

.organizer,
.thanyoupage .cbtn,
a {
    display: inline-block
}

.organizer {
    color: #222;
    font-size: 20px;
    text-transform: none !important
}

.organizers {
    width: 46%;
    margin: 0 auto;
    padding-top: 30px
}

.selecttable,
.unselecttable {
    width: 108px;
    height: 30px;
    float: right
}

.apply,
.ok {
    margin-top: 29px
}

.cancel,
.gt__btn {
    border-radius: 5px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px 45px;
    line-height: normal;
    transition: .2s ease-in-out
}

.organizers .slick-slider {
    padding-top: 10px !important
}

.gt__btn {
    background: #253e93;
    color: #fff;
    border: 1px solid #253e93;
    font-size: 16px;
    cursor: pointer;
    position: relative
}

.mypro img {
    width: 16px;
    margin-right: 8px;
    margin-top: 2px;
    float: left
}

.mypro p {
    float: left;
    margin: 2px 0
}

.gt__user-sec textarea {
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 11px 15px;
    width: 100%;
    box-sizing: border-box;
    color: #222;
    font-size: 14px;
    max-width: 540px;
    min-width: 540px
}

.gt__footer-logo img,
.img-fluid,
header.gt__fixed-heder .gt__header-logo img {
    max-width: 100%
}

.organizername {
    font-size: 18px
}

.cancel {
    background-color: #fff;
    color: #f05f23;
    border: 1px solid #f05f23;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    margin-right: 20px
}

.no,
.ok {
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    background: #f05f23;
    color: #fff;
    padding: 10px 45px;
    transition: .2s ease-in-out;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize
}

.no {
    border: 1px solid #f05f23
}

.ok {
    border: 1px solid #f05f23;
    margin-left: 44px
}

.apply {
    background: #f05f23;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    text-transform: capitalize;
    padding: 10px 45px;
    line-height: normal;
    transition: .2s ease-in-out;
    border: 1px solid #f05f23;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    margin-left: 4px
}

.gt__bg-image,
.gt__bg-image1 {
    background-position: center center;
    position: relative
}

.gt__bg-image,
.gt__bg-image1,
.thanyoupage {
    background-size: cover
}

.gt__bg-image,
.gt__bg-image1,
.thanktexts .micon {
    background-repeat: no-repeat
}

.selecttable,
.thanyoupage .cbtn,
.unselecttable {
    font-weight: 600;
    font-size: 16px;
    cursor: pointer
}

.htext {
    margin-top: 53px
}

.bottomfixd .seatinfo ul,
.gt__organizer-sec .gt__form-btn,
.title,
a {
    margin: 0
}

.mypro {
    color: #000
}

.selecttable {
    color: #fff;
    border-radius: 5px;
    border: none;
    background-color: #f05f23
}

.unselecttable {
    color: #f05f23;
    border-radius: 5px;
    border: 1px solid #f05f23;
    background-color: transparent
}

.cbtn1,
.cbtn2 {
    border: 1px solid #f05f23 !important
}

.gt__btn:hover {
    background-color: #f05f23;
    border-color: #f05f23
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.d-flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.justify-content-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.img-fluid {
    height: auto
}

.txt-white {
    color: #fff
}

.gt__form-grp.gt__reg-link,
.mb-0,
.ticketsec p {
    margin-bottom: 0
}

.mt-0,
.tickets .details .info .text .ticket-breakup li:first-child,
tickets .details .info .text .__summary ul li:first-child {
    margin-top: 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    text-transform: capitalize;
    margin: 0;
    font-family: Roboto, sans-serif;
    font-weight: 500
}

h1,
h2 {
    font-size: 25px;
    line-height: 1.5;
    text-transform: uppercase
}

h3 {
    text-transform: none !important
}

a {
    font-size: 100%;
    text-decoration: none;
    vertical-align: baseline;
    background: 0 0
}

a:hover {
    text-decoration: unset
}

.gt__bg-image1 {
    background-image: url("../images/main-bg.jpg") !important
}

.cbtn1 {
    background-color: #f05f2300 !important;
    color: #f05f23 !important
}

.cbtn1:hover,
.cbtn2 {
    background-color: #f05f23 !important;
    color: #fff !important
}

.gt__user-details.gt__img-middle {
    position: relative
}

.afterlogin {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    min-width: 140px;
    position: absolute;
    z-index: 22;
    right: 0;
    top: 38px;
    padding: 10px
}

.afterlogin::before {
    content: "";
    position: absolute;
    top: -12px;
    right: 9px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #e7e8e6
}

.afterlogin ul {
    margin: 0 !important
}

.afterlogin li a {
    padding: 5px 6px;
    font-size: 14px
}

.subtitle {
    max-width: 576px;
    margin: 36px auto;
    letter-spacing: .1em;
    line-height: 1.3em
}

.laaqRQ,
.lcuusL {
    line-height: 24px
}

.countdown-wrapper {
    width: fit-content;
    display: flex;
    justify-content: center;
    margin: -14px -8px 0;
    float: left
}

.time-section {
    padding: 0 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #f05f23 !important
}

.time {
    margin: 0;
    font-size: 25px !important;
    font-weight: 800 !important
}

.small {
    font-size: 18px !important
}

main.myticket1 {
    padding-top: 120px
}

.thanyoupage {
    background-image: url('../images/main-bg.jpg');
    background-position: 0 50%;
    padding: 100px 0
}

.thanyoupage .cbtn {
    height: 40px;
    line-height: 40px;
    min-width: 100px;
    padding: 0 15px;
    background-color: #f05f23;
    color: #fff;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px
}

.booking-details:before,
.profile-container .profile-tabs:before,
.tickets .details .info .text .__summary:before,
.tickets .details .info .text .ticket-breakup:before,
.tickets .details .info .text .total:before {
    content: " ";
    display: table;
    line-height: 0
}

.thanktexts {
    background-color: #fff;
    border-radius: 20px;
    padding: 30px
}

.thanktexts .micon {
    width: 70px;
    height: 70px;
    margin: 0 auto 30px;
    background-image: url(../images/email-icon-w.svg);
    background-position: 50% 50%;
    background-color: #f05f23;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%
}

.thanktexts h3 {
    font-size: 24px
}

.gt__btn,
.thanktexts p {
    margin-top: 5px
}

.thanktexts .cbtn {
    margin-top: 10px
}

.iKHtID {
    width: 48%;
    margin: 16px auto;
    border: 1px solid #ddd
}

.fcfhSc {
    height: 58px;
    padding: 18px 64px;
    border-radius: 4px 4px 0 0;
    background: linear-gradient(98.18deg, #2b3148 2.87%, rgb(136 87 63) 59.96%, rgb(235 99 78) 98.36%)
}

.ihcMDi,
.kcqWmw {
    background: #fff
}

.jVWMnN {
    display: flex;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    margin: auto
}

.fifige {
    color: #fff;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 700
}

.categorysec.gt__user-sec.categorysec3{
    padding: 40px 30px 400px !important;
}

.gjGbrl {
    width: 94px;
    height: 94px;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex
}

.ihcMDi,
.kukXfn {
    width: 100%;
    display: flex
}

.ihcMDi {
    cursor: pointer
}

.cRQwSB {
    margin: auto
}

.gt__form-grp.gt__uname>div.space1,
.margin {
    margin-top: 20px
}

.kcqWmw {
    padding: 36px 64px 0;
    border-radius: 0 0 4px 4px
}

.lcuusL {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.hrZxnn,
.laaqRQ {
    font-weight: 400
}

.laaqRQ {
    font-size: 16px;
    letter-spacing: .2px;
    color: #000;
    margin-right: 8px
}

.padding {
    padding: 26px 40px 40px !important
}

.hrZxnn {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: .2px;
    color: #006616;
    border-radius: 2px;
    padding: 1px 4px;
    background: #c9f8d3
}

.eWtCsq,
.jwlkLg {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .2px;
    font-weight: 400
}

.AQuNp {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer
}

.XPsjJ {
    display: flex;
    margin-right: 4px
}

.jwlkLg {
    color: #dc354b
}

.hJcxfv {
    display: flex;
    padding-bottom: 36px;
    -webkit-box-align: center;
    align-items: center
}

.eWtCsq {
    color: #333;
    min-width: 140px
}

.kukXfn {
    -webkit-box-pack: justify;
    justify-content: space-between
}

.my_ticket p,
.tickets ul {
    padding-left: 35px
}

.profile-container .profile-tabs .confirmation-list .confirmation-card {
    clear: both;
    margin-bottom: 30px
}

.tickets .details {
    float: left;
    background: #fff;
    width: 63%;
    border-radius: 3px;
    border: 1px solid #dfddde;
    box-shadow: 0 0 0 3px #f1f1f1
}

.tickets .details:before {
    top: -12px;
    box-shadow: inset 0 -2px 0 #dfddde
}

.tickets .details:after,
.tickets .details:before {
    position: absolute;
    display: inline-block;
    content: '';
    width: 24px;
    height: 24px;
    background: #f2f2f2;
    border-radius: 24px;
    right: 23.7%;
    z-index: 1
}

.tickets .confirmation-card .details .unpaid-tag {
    position: absolute;
    text-transform: uppercase;
    top: 7px;
    right: -9px;
    background: #c02c39;
    color: #fff;
    padding: 5px 7px;
    font-size: 10px;
    z-index: 1
}

.tickets .details .bcode {
    float: right;
    position: relative;
    width: 25%;
    padding: 30px 25px;
    text-align: center
}

.tickets .details .bcode .tkt-cancellation {
    width: 100px;
    height: 100px
}

.tickets .details .info {
    float: left;
    position: relative;
    width: 75%;
    border-right: 1px dashed #e3e3e3;
    padding: 15px 15px 5px
}

.tickets .details .info .__img {
    float: left;
    width: 112px;
    min-height: 1px
}

.containerc input:checked~.checkmark:after,
.tickets .details .info .__img img {
    display: block
}

.tickets .details .info .text {
    float: left;
    width: 75%;
    padding: 5px 10px 7px 25px
}

.tickets .details .info .text>div:first-child {
    padding-top: 0
}

.tickets .details .info .text>div {
    padding: 25px 0 15px;
    text-align: left;
    position: relative
}

.tickets .details .info .text>div h1 {
    font-size: 16px;
    color: #0e1422;
    width: 70%
}

.tickets .details .info .text div .ticketMode {
    width: 70px;
    height: 19px;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333544
}

.tickets .details .info .text>div p {
    color: #7b8086;
    font-size: 12px;
    line-height: 18px;
    padding-left: 0 !important
}

.tickets .details .info .text .inner-text>span.Qyt {
    display: inline-block;
    margin-top: 10px
}

.cls-2,
.share_offer .cls-11,
.tickets .details .info .text .inner-text>span {
    font-size: 12px
}

.tickets .details .info .text>div .__seats {
    color: #5d5f61;
    font-size: 12px;
    font-weight: 400
}

.tickets .details .info .text>div .__seats .icon-seat {
    width: 20px;
    height: 20px;
    margin-top: 5px
}

.tickets .details .info .text>div .__seats .icon-seat svg {
    fill: #5d5f61
}

.tickets .details .info .text>div .__seats strong {
    display: inline-block;
    font-size: 15px;
    width: auto;
    vertical-align: top;
    margin: 5px 0
}

.cls-14,
.cls-15,
.cls-3,
.share_offer .cls-13,
strong {
    font-weight: 700
}

.containers2,
.gt__search-box,
.hide,
.search_mobile,
.tickets .details .info .text>div .__seats strong+span,
.ticketsec3 .ticketinfo::after,
span.menu-close {
    display: none
}

.tickets .details .info .text div.booking-summary {
    display: none;
    min-width: 265px;
    border: 1px solid #dbdada;
    text-align: left;
    padding: 9px 13px 9px 39px;
    margin: 0 0 17px;
    font-size: 13px;
    float: left
}

.tickets .details .info .text div.booking-summary .__btnBooking {
    color: #706e6e
}

.cityfilter {
    width: 200px
}

.tickets .details .info .text div.booking-summary .icon {
    width: 24px;
    position: absolute;
    top: 5px;
    left: 7px
}

.event_ticket_info li,
[class*=icon] {
    display: inline-block
}

.tickets .details .info .text div.old-booking-summary {
    padding: 0 0 20px;
    clear: both
}

.tickets .details .info .text div.old-booking-summary .old-bkText {
    color: #7b8086;
    font-size: 12px;
    line-height: 18px
}

.tickets .details .info .text div.old-booking-summary .bk-num {
    color: #5d5f61;
    font-size: 15px;
    font-weight: 700
}

.tickets .details .info .text .ticket-breakup {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    padding: 15px 0;
    float: left;
    width: 100%;
    zoom: 1
}

:before {
    box-sizing: inherit
}

.tickets .details .info .text .ticket-breakup li {
    width: 100%;
    float: left;
    line-height: 14px;
    margin-top: 10px
}

.tickets .details .info .text .ticket-breakup li span {
    width: 50%;
    float: left
}

.tickets .details .info .text .ticket-breakup li span:last-child {
    text-align: right;
    padding-left: 15px
}

.tickets .details .info .text .__summary {
    color: #999;
    font-size: 12px;
    font-weight: 400;
    padding: 15px 0;
    border-top: 1px solid #e3e3e3;
    zoom: 1;
    float: left;
    width: 100%
}

.tickets .details .info .text .__summary ul {
    line-height: 19px
}

.tickets .details .info .text .__summary ul li {
    width: 100%;
    float: left;
    line-height: 14px
}

.booking-details:after,
.tickets .details .info .text .__summary:after,
.tickets .details .info .text .total:after {
    clear: both
}

.tickets .details .info .text .total {
    color: #666;
    padding: 10px 0;
    border-top: 1px dashed #e3e3e3;
    float: left;
    width: 100%;
    zoom: 1
}

.tickets .details .info .text .total .__text {
    position: relative;
    color: inherit;
    font-size: 11px;
    cursor: pointer;
    margin-top: 5px;
    display: inline-block;
    vertical-align: top
}

.tickets .details .info .text .total .__value {
    float: right;
    font-size: 20px;
    font-weight: 700;
    color: #1f2533
}

.tickets .details .info .text .loyalty-refund {
    width: 100%;
    padding: 0
}

.booking-details {
    text-align: left;
    zoom: 1;
    width: 63%;
    display: inline-block;
    margin-top: 10px
}

.booking-details .gt__btn {
    float: right;
    margin-top: 5px
}

.booking-details p {
    float: left;
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    color: #868c92;
    margin-right: 50px
}

.contact_data p,
.info .cinfobox p {
    line-height: 24px
}

.booking-details p span {
    transition: .3s;
    color: #5d5f61;
    display: inline-block;
    margin-top: 10px;
    font-size: 12px
}

.profile-container .profile-tabs {
    zoom: 1;
    min-height: 220px;
    padding: 40px 50px
}

.tickets .details:after {
    bottom: -12px;
    box-shadow: inset 0 2px 0 #dfddde
}

path,
svg,
text,
tspan {
    font-family: Muli, sans-serif;
    font-weight: 700
}

path {
    cursor: pointer
}

.cls-2,
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.share_offer .cls-1 {
    fill: #fff
}

.share_offer .cls-7 {
    letter-spacing: -.11em
}

.share_offer .cls-8 {
    fill: #F05F23
}

.share_offer .cls-9 {
    fill: #dbdbdb
}

.share_offer .cls-10 {
    letter-spacing: -.01em
}

.cls-11,
.cls-12,
.share_offer .cls-10 {
    font-family: Gotham-Book, Gotham
}

.share_offer .cls-13 {
    font-family: Gotham-Bold, Gotham
}

.share_offer .cls-16 {
    fill: #253E93
}

.share_offer .cls-17 {
    fill: #f94158
}

.cls-6,
.share_offer .cls-18 {
    font-size: 13px
}

.share_offer .cls-19 {
    letter-spacing: 0
}

.share_offer .cls-20 {
    fill: #44a3f2
}

.share_offer .cls-21 {
    letter-spacing: -.09em
}

.share_offer .cls-22 {
    fill: #4ed014
}

.share_offer .cls-3 {
    font-family: ProximaNova-Bold, 'Proxima Nova';
    font-size: 18.85px
}

.share_offer .cls-14 {
    font-size: 11.64px
}

.cls-15,
.share_offer .cls-14 {
    font-family: Muli-ExtraBold, Muli
}

.share_offer .cls-23 {
    fill: #9845ef
}

.share_offer .cls-15 {
    font-size: 12.8px
}

.cls-24,
.share_offer .cls-4 {
    font-size: 16.57px
}

.share_offer .cls-5 {
    isolation: isolate;
    opacity: 0
}

.share_offer .cls-4 .cls-10,
.share_offer .cls-4 .cls-12,
.share_offer .cls-4 .cls-13,
.share_offer .cls-6 .cls-12,
.share_offer .cls-6 .cls-13 {
    fill: white !important
}

.share_offer text.cls-6,
.share_offer tspan.cls-10 {
    opacity: unset !important
}

.share_offer .cls-18 .cls-10,
.share_offer .cls-18 .cls-12,
.share_offer .cls-18 .cls-13 {
    fill: black !important
}

.share_offer tspan.cls-7 {
    opacity: unset;
    fill: black
}

.share_offer .cls-24 .cls-12,
.share_offer .cls-24 .cls-13,
.share_offer text.cls-11 {
    fill: black
}

.share_offer .cls-2 .cls-12,
.share_offer .cls-2 .cls-13 {
    fill: white
}

.share_offer.box_split_section {
    margin-top: 0 !important;
    background-color: #f1f1f1
}

.share_offer.box_split_section .ng-binding {
    width: 940px;
    margin: 0 auto
}

.event_ticket_info .ticket_info_row {
    padding: 7px 0;
    display: flex;
    flex-flow: wrap
}

.ticket_category_desc,
.ticketsec .venueinfo {
    font-size: 14px;
    font-weight: 400
}

.ptkselectseat {
    cursor: default
}

.ptkselectseat span {
    padding: 8px 14px;
    background: #253e93;
    color: #fff;
    border: 0;
    transition: .2s linear;
    cursor: default;
    display: inline-block
}

.blockTicket,
.slick-slide img {
    width: 100%
}

.event_ticket_info .action_btn {
    text-align: right
}

.event_ticket_info .action_btn .btn_premier_01 {
    padding: 8px 14px;
    font-size: 15px;
    width: 118px
}

.event_ticket_info .ticket_category_n1,
.ticket_category_n1 {
    font-size: 17px;
    flex: 1
}

.event_ticket_info {
    background: #f1f1f1;
    width: 90% !important;
    margin: 20px auto;
    padding: 10px 20px;
    color: #000;
    font-size: 15px
}

.btn_premier_01 {
    background-color: #f05f23;
    color: #fff;
    border: 0;
    transition: .2s linear;
    display: inline-block;
    text-align: center
}

.gt_social_icons button {
    padding: 2px !important
}

.contact_data {
    text-align: center;
    padding: 0 6% 30px
}

.contact_data p {
    padding-top: 5px
}

.info .office h1 {
    padding-bottom: 0
}

.info .cinfobox {
    width: 25%;
    display: inline-block;
    padding: 30px 30px 0 0;
    vertical-align: top
}

.info .cinfobox h3 {
    position: relative;
    padding-left: 15px
}

.info .cinfobox h3:after {
    width: 4px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background-color: #f05f23
}

.info .cinfobox a {
    color: #000;
    text-decoration: underline;
    margin-top: 20px;
    line-height: 24px
}

.ctectinfo p a:hover,
.ctextinfo p a:hover,
.info .cinfobox a:hover,
.notetext,
.notetext .cpoint li {
    color: #f05f23
}

.containerc {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.containerc input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.slide-height {
    height: auto;
    background-color: #d3d3d3
}

.checkmark,
.header {
    background-color: #fff
}

.cbtns {
    font-size: 20px !important;
    line-height: 51px !important
}

.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 2px;
    border: 1px solid #253e93;
    cursor: pointer
}

.containerc:hover input~.checkmark {
    background-color: transparent
}

.cbtn:hover,
.containerc input:checked~.checkmark {
    background-color: #253e93
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.containerc .checkmark:after {
    left: 3px;
    top: 0;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.gt__check-box,
.gt__search-block,
.gt__user-details.gt__img-middlesubmenu {
    position: relative
}

.header,
header.gt__fixed-heder {
    position: fixed;
    top: 0;
    width: 100%
}

.gt__header-logo img,
.slide1 {
    height: auto
}

header .gt__header-logo img {
    height: 100%;
    width: 100%
}

.gt__header-wrap .gt__header-left {
    max-width: 12%;
    flex: 0 0 12%
}

.gt__header-wrap {
    padding: 15px 30px
}

.gt__header-wrap .gt__header-right {
    max-width: 88%;
    flex: 0 0 88%
}

.gt__header-menu ul li a {
    color: #222;
    font-weight: 500;
    text-transform: capitalize;
    position: relative
}

.header {
    z-index: 9999
}

a.gt__header-logo.active {
    text-align: left
}

.gt__header-menu ul li a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 25px;
    left: 0;
    pointer-events: none;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition: -webkit-clip-path .3s, -webkit-transform .3s cubic-bezier(.2, 1, .8, 1);
    transition: clip-path .3s, transform .3s cubic-bezier(.2, 1, .8, 1), -webkit-clip-path .3s, -webkit-transform .3s cubic-bezier(.2, 1, .8, 1);
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)
}

.gt__footer-menu ul li a,
.gt__location img,
.gt__search-input img,
.gt__social-img span,
.gt__social-img>div:hover span,
.gt__title-wrap a,
.gt__user-details img,
header.gt__fixed-heder {
    transition: .2s ease-in-out
}

.gt__header-menu ul li a:hover:before {
    -webkit-transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
    transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
    -webkit-clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%)
}

.gt__header-menu ul li {
    padding: 0 10px
}

.gt__header-menu {
    padding-left: 30px
}

.gt__img-middle img,
.gt__img-middle span {
    display: inline-block;
    vertical-align: middle
}

.location_bar,
.search_bar {
    border: 1px solid #dfdfdf;
    padding: 5px 10px !important
}

.location_bar select,
.search_bar input {
    border: none;
    background-color: transparent
}

.gt__search-box input,
.gt__user-sec input {
    background-color: #eee;
    border: 1px solid #ddd;
    box-sizing: border-box
}

.location_bar select:focus,
.search_bar input:focus {
    border: none;
    box-shadow: none;
    outline: 0
}

.location_bar {
    margin-left: 15px
}

.gt__header-socialsec>div {
    padding: 0 15px
}

.gt__user-details.gt__img-middlesubmenu a span {
    position: relative;
    top: -6px
}

.gt__search-input a {
    margin-top: 8px
}

.gt__img-middle a,
.gt__img-middle span {
    font-weight: 500;
    color: #000;
    cursor: pointer
}

.gt__search-block.gt__search-active .gt__search-box {
    display: block;
    position: absolute;
    right: 0;
    padding: 0;
    top: 100%
}

header.gt__fixed-heder {
    padding: 0;
    box-shadow: 0 0 3px rgb(0 0 0 / 10%);
    z-index: 99
}

header.gt__fixed-heder .gt__header-wrap {
    padding: 0 30px;
    background-color: #fff
}

.gt__events-block:hover .gt__img-block img,
.gt__location:hover img,
.gt__search-input:hover img,
.gt__user-details:hover img {
    transform: scale(1.1)
}

.gt__search-open .gt__search-box {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9;
    background-color: #000;
    width: 100%;
    text-align: center;
    padding: 15px
}

.gt__call-sec h4,
.gt__number-sec span {
    display: inline-block
}

.gt__search-box input {
    border-radius: 5px;
    padding: 10px 15px;
    width: 80%;
    color: #222;
    font-size: 14px;
    margin: 0 auto
}

.gt__call-sec .gt__number-sec a,
.gt__call-sec h4 {
    font-size: 25px;
    font-weight: 500;
    color: #fff
}

.gt__search-box input:focus {
    outline: 0;
    border-color: #253e93
}

.gt__search-box .gt__close {
    font-size: 20px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    margin: 14px;
    color: #fff;
    cursor: pointer
}

.gt__call-sec .gt__number-sec {
    display: inline-block;
    padding-left: 30px
}

.gt__number-sec span {
    padding-left: 5px
}

.gt__call-sec {
    background-color: #f05f23;
    padding: 21px 0
}

footer {
    clear: both;
    bottom: 0
}

.gt__footer-wrap .gt__footer-right {
    max-width: 75%;
    flex: 0 0 75%;
    background-color: #000;
    border-radius: 317px 0 0;
    padding: 80px 0 0
}

.gt__footer-wrap .gt__footer-left {
    max-width: 25%;
    flex: 0 0 25%;
    padding: 60px 20px 80px 30px
}

.gt__footer-rightwrap {
    padding-left: 15%;
    padding-right: 30px
}

#allcity {
    position: absolute;
    bottom: 25%;
    left: 41%;
    transform: translateY(-50%);
    z-index: 999;
    background: #253e93;
    border: 2px solid var(--toastify-color-light);
    color: var(--toastify-color-light)
}

.gt__footer-right h3 {
    color: #fff;
    font-family: Roboto, sans-serif;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px
}

.gt__footer-rightwrap .gt__footer-menu {
    max-width: 28%;
    flex: 0 0 28%
}

.gt__footer-rightwrap .gt__footer-payment {
    max-width: 44%;
    flex: 0 0 44%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin: 7px 0 0
}

.gt__bootom-title,
.gt__footer-paymentinner .gt__title {
    background-color: #000;
    margin: 0 auto;
    display: inline-block;
    left: 0;
    right: 0
}

.gt__footer-paymentinner {
    border: 2px solid #fff;
    border-radius: 5px;
    padding: 43px 25px;
    position: relative
}

.gt__footer-rightwrap .gt__copywriti {
    max-width: 100%;
    flex: 0 0 100%;
    border-top: 1px solid rgba(221, 221, 221, .3);
    padding: 22px 0
}

.gt__footer-menu ul li {
    padding: 7px 0
}

.gt__footer-menu ul li a {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize
}

.gt__footer-menu ul li:hover a {
    color: #f15a2b
}

.gt__footer-paymentinner .gt__title {
    position: absolute;
    top: -12px;
    max-width: 190px
}

.gt__bootom-title .gt__lock,
.gt__bootom-title .gt__lock-text {
    background-color: #fff;
    vertical-align: middle
}

.gt__footer-paymentinner .gt__title span:nth-child(2) {
    padding-left: 12px;
    text-transform: uppercase
}

.gt__bootom-title {
    position: absolute;
    bottom: -15px;
    max-width: 140px
}

.gt__bootom-title .gt__lock-text {
    color: #222;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    padding: 5px 8px;
    margin-left: -10px;
    border-radius: 0 3px 3px 0
}

.gt__bootom-title .gt__lock,
.gt__social-img a {
    width: 30px;
    line-height: 30px;
    border-radius: 100%;
    display: inline-block;
    height: 30px
}

.gt__copywriti p {
    color: #fff;
    font-size: 14px;
    margin: 0
}

.gt__social-img a {
    text-align: center
}

.gt__social-img a img {
    vertical-align: middle
}

.gt__social-img>div {
    padding-right: 5px
}

.gt__reg-link .gt__org-link,
.gt__social-img {
    padding: 20px 0 0
}

.gt__social-img .gt__mssage a {
    background-color: #009ff6
}

.gt__social-img .gt__whatspp a {
    background-color: #24cc63
}

.gt__social-img .gt_twitter a {
    background-color: #1c9cea
}

.gt__social-img .gt_facebook a {
    background-color: #4064ac;
    line-height: 32px
}

.gt__social-img .gt_snapchat a {
    background-color: #fffc00
}

.gt__social-img .gt_instragram a {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    box-shadow: 0 3px 10px rgba(0, 0, 0, .25)
}

.notetext {
    width: 59%;
    float: left;
    padding: 10px;
    border: .5px solid #f05f23
}

.gt__footer-social {
    padding: 30px 0 0
}

.gt__social-img span {
    display: inline-block;
    font-size: 0;
    color: #222;
    text-transform: capitalize
}

.gt__social-img>div:hover span {
    font-size: 14px;
    cursor: pointer
}

.gt__user-sec h1 {
    padding-bottom: 90px
}

.gt__user-sec input {
    border-radius: 5px;
    padding: 11px 15px;
    width: 100%;
    color: #222;
    font-size: 14px
}

.gt__check-box input {
    width: auto
}

.gt__login-googlefb>div {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 9px 15px;
    display: inline-block;
    margin: 0 5px
}

.gt__login-googlefb>div img,
.slick-slide div {
    vertical-align: middle
}

.gt__login-googlefb>div a span {
    color: #000;
    text-transform: capitalize;
    font-weight: 500;
    vertical-align: middle;
    padding-left: 5px
}

.gt__user-wrap {
    border: 1px solid #ddd;
    border-radius: 5px;
    max-width: 48%;
    margin: 0 auto;
    padding: 70px 40px 40px;
    position: relative;
    background-color: #fff
}

.gt__user-sec .gt__user-icn {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: -7%;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 80px;
    height: 80px;
    line-height: 105px;
    border-radius: 100%
}

.gt__user-sec .gt__user-title span {
    color: #777;
    font-size: 12px;
    font-weight: 400;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    top: -8px;
    max-width: 137px;
    margin: 0 auto;
    text-align: center
}

.gt__user-sec .gt__user-title {
    border-bottom: 1px solid #ddd;
    position: relative
}

.gt__user-sec .gt__user-title.gt__user-title1 {
    border-bottom: 1px solid #ddd;
    position: relative;
    margin-bottom: 39px
}

.gt__user-sec .gt__form-block {
    padding: 40px 0 0
}

.gt__user-sec .gt__form-block.gt__form-block1 {
    padding: 40px 0 0;
    margin-top: -64px
}

.gt__user-sec .gt__form-block form {
    padding: 25px 0 0
}

.gt__user-sec .gt__form-grp {
    margin-bottom: 25px;
}
#input_passowrd {
    position: relative;
  }
  
  #togglePassword {
    margin: 0;
    position: absolute;
    top: 54%;
    right: 7%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #777;
  }

.gt__form-grp label {
    color: #222;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding-bottom: 10px
}

.gt__form-grp.gt__form-pass {
    margin-bottom: 10px
}

.gt__forgot-pass a {
    color: #253e93;
    font-size: 14px
}

.gt__form-grp.gt__reg-link p {
    margin: 0;
    font-size: 14px
}

.gt__form-grp.gt__reg-link a {
    color: #253e93;
    border-bottom: 1px solid #253e93;
    margin-left: 7px
}

.gt__form-grp .gt__check-box label {
    padding: 0 0 0 22px;
    vertical-align: middle
}

.gt__check-box a {
    color: #253e93
}

.gt__form-grp.gt__uname>div {
    max-width: 48%;
    flex: 0 0 48%
}

.gt__user-sec input:focus {
    outline: 0;
    border-color: #253e93;
    background-color: transparent
}

.gt__hero-wrap {
    padding: 18% 0
}

.bottomfixd.gt__user-sec,
.gt__space-around {
    padding: 0 30px
}

.gt__upcomming-sec {
    padding: 60px 0
}

.gt__title-wrap {
    padding: 0 0 30px
}

.gt__events-content p {
    line-height: 1.5em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    color: #222;
    font-weight: 400;
    margin: 0
}

.gt__events-block .gt__img-block img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: .2s ease-in-out
}

.gt__events-topsec .gt__img-block {
    padding-top: 162%;
    position: relative;
    overflow: hidden
}

.gt__events-topsec {
    position: relative
}

.gt__events-date {
    background-color: #000;
    padding: 6px 10px;
    border-radius: 0 0 5px 5px;
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%
}

.cbtn,
.gt-top-button a:hover,
.notetext .cpoint li:after {
    background-color: #f05f23
}

.gt__events-date span {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #fff
}

.ctitle,
.gt__title-wrap a {
    color: #000;
    font-weight: 500;
    font-family: Roboto, sans-serif
}

.gt__title-wrap a {
    border-bottom: 1px solid #000
}

.gt__title-wrap a:hover {
    color: #f05f23;
    border-color: #f05f23
}

.gt__events-wrap {
    margin: 0 -15px
}

.gt__events-wrap .gt__events-block {
    padding: 0 20px;
    flex: 0 0 20%
}

.gt__events-content .gt__btn {
    font-size: 14px;
    padding: 9px 22px;
    margin-bottom: 30px
}

.gt__nearevent-sec {
    padding: 60px 0;
    top: -6px
}

.gt__ring-img {
    position: absolute;
    right: 8%;
    top: 2%
}

.gt__line-img {
    position: absolute;
    bottom: 5%;
    right: 21%
}

.gt__ring-bootom-img {
    position: absolute;
    bottom: 5%;
    left: 18%
}

.gt__polygon-img {
    position: absolute;
    left: 40%;
    top: 3%
}

@-webkit-keyframes round {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes round {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }

    to {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

.round-animation {
    -webkit-animation: 45s linear infinite round;
    animation: 45s linear infinite round
}

@-webkit-keyframes up-down {
    0% {
        -webkit-transform: translate(1px, 20px);
        transform: translate(1px, 20px)
    }

    24% {
        -webkit-transform: translate(1px, 30px);
        transform: translate(1px, 30px)
    }

    50% {
        -webkit-transform: translate(1px, 12px);
        transform: translate(1px, 12px)
    }

    100%,
    74% {
        -webkit-transform: translate(1px, 22px);
        transform: translate(1px, 22px)
    }
}

@keyframes up-down {
    0% {
        -webkit-transform: translate(1px, 20px);
        transform: translate(1px, 20px)
    }

    24% {
        -webkit-transform: translate(1px, 30px);
        transform: translate(1px, 30px)
    }

    50% {
        -webkit-transform: translate(1px, 12px);
        transform: translate(1px, 12px)
    }

    100%,
    74% {
        -webkit-transform: translate(1px, 22px);
        transform: translate(1px, 22px)
    }
}

.up-down {
    -webkit-animation: 4s linear infinite up-down;
    animation: 4s linear infinite up-down;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.gt__tickets-sec {
    padding: 45px 0
}

.gt__tickets-wrap .gt__tickets-content {
    max-width: 66.66%;
    flex: 0 0 66.66%
}

.gt__tickets-wrap .gt__img-block {
    max-width: 33.33%;
    flex: 0 0 33.33%
}

.gt__tickets-wrap {
    max-width: 80%;
    margin: 0 auto
}

.gt__tickets-sec p {
    color: #222;
    font-size: 14px;
    line-height: 24px
}

.gt-top-button {
    position: fixed;
    right: 40px;
    bottom: 20px;
    z-index: 99
}

.gt-top-button a {
    background-color: #000;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 100%;
    padding: 5px;
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;
    border: 2px solid #fff
}

.gt-top-button img {
    position: relative;
    top: -4px
}

.container {
    max-width: 1170px;
    margin: 0 auto
}

.eventinfo ul li .desc p.price {
    font-size: 14px
}

.ctitle {
    display: inline-block;
    font-size: 25px;
    line-height: 22px
}

.cbtn {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    line-height: 35px;
    padding: 0 19px
}

.ctectinfo p,
.ctextinfo p {
    color: #222;
    font-size: 16px;
    line-height: 26px;
    margin: 20px 0 0
}

.ctectinfo p a,
.ctextinfo p a {
    color: #253e93;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline
}

.ctectinfo h5,
.ctextinfo h5 {
    font-size: 20px;
    line-height: 35px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    display: inline-block
}

.cpoint {
    float: left;
    width: 100%;
    margin: 5px 0;
    color: #000
}

.cpoint li {
    color: #000;
    font-size: 16px;
    margin: 3px 0;
    line-height: 22px;
    position: relative;
    padding-left: 15px
}

.cpoint li:after {
    background-color: #000;
    width: 7px;
    height: 7px;
    position: absolute;
    left: 0;
    top: 6px;
    content: "";
    border-radius: 7px;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px
}

.eventinfo .eventtitle {
    float: left;
    width: 100%;
    padding: 45px 0;
    position: relative;
    border-bottom: 1px solid #d9d9d9
}

.eventinfo .eventtitle .cbtn {
    position: absolute;
    right: 0;
    top: 45px
}

.eventinfo .eventtitle p {
    display: inline-block;
    width: 100%;
    color: #222;
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 0
}

.eventinfo ul {
    width: 100%;
    float: left
}

.eventinfo ul li {
    margin: 20px 0;
    float: left;
    width: 29%;
    padding: 0 20px;
    position: relative;
    border-right: 1px solid #d9d9d9
}

.__tax-summary ul,
.eventinfo ul li:first-child,
.my_ticket p.bookingno {
    padding-left: 0
}

.eventinfo ul li:last-child {
    padding-right: 0;
    border-right: none;
    width: 26%
}

.eventinfo ul li .eventinfo ul li .icon,
.eventinfo ul li .icon,
.icon {
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 50px;
    float: left;
    height: 61px
}

.eventinfo ul li:nth-child(3n+0) {
    width: 15%
}

.eventinfo ul li .icon1 {
    background-image: url("../images/calender.svg")
}

.eventinfo ul li .icon2 {
    background-image: url("../images/loction.svg")
}

.eventinfo ul li .icon3 {
    background-image: url("../images/ticket.svg")
}

.eventinfo ul li .desc {
    margin-left: 52px
}

.eventinfo ul li .desc h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px
}

.eventinfo ul li .desc p {
    color: #777;
    font-size: 16px;
    line-height: 26px;
    margin: 0
}

.eventinfo ul li .time {
    margin: 15px 0 0 10px;
    color: #f05f23;
    font-size: 25px;
    font-weight: 500;
    line-height: 40px;
    background-repeat: no-repeat;
    background-position: 0 0
}

.eventabout {
    background-image: url("../images/main-bg.jpg");
    float: left;
    width: 100%;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0
}
.eventabout  span{
    text-transform: uppercase;
}
.eventabout .eventimgbox {
    float: left;
    width: 251px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px
}

.eventabout .eventimgbox img {
    object-fit: cover;
    margin: 0 auto
}

.eventabout .eventtext {
    margin-left: 315px
}

.eventabout .eventtext .interested {
    margin-top: 25px;
    width: 100%;
    display: inline-block;
    position: relative
}

.eventabout .eventtext .interested .cbtn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer
}

.eventabout .eventtext .interested .like {
    background-image: url("../images/like.svg");
    margin: 5px 0 3px;
    line-height: 24px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 33px
}

.eventabout .eventtext .interested p {
    font-size: 14px;
    line-height: 22px;
    margin: 0
}

.eventabout .eventtext .gt__social-img {
    padding-left: 10px;
    padding-top: 0
}

.eventabout .eventtext .gt__social-img a {
    position: relative
}

.eventabout .eventtext .gt__social-img a img,
.sponsorbox .imgbox img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.eventabout .eventtext .gt__social-img span {
    padding-left: 5px
}

.sponsorsection {
    padding: 38px 0;
    float: left;
    width: 100%
}

.sponsorbox .imgbox {
    width: 100px;
    height: 100px;
    float: left;
    border: 1px solid #ddd;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: relative
}

.sponsorbox .desc {
    margin-left: 127px
}

.sponsorbox .desc h3 {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    font-family: Roboto, sans-serif;
    text-align: left;
    text-transform: capitalize !important
}

.sponsorbox .desc p {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
    text-align: left
}

.qrcode {
    padding-left: 549px;
    width: 200px
}

.my_ticket,
.seatlayout,
.seatlayout .seatlayoutbox,
.seatlayout .seatlayoutbox .seatarrangement .seatrow,
.tableview>div {
    width: 100%;
    float: left
}

.seatlayout {
    background-size: cover;
    background-position: 0 50%;
    background-color: #f9f9f9;
    min-height: 100vh;
    padding: 100px 40px !important
}

.seatlayout .seatlayoutbox {
    padding: 0 100px
}

.seatlayout .seatlayoutbox .seathead h3 {
    font-size: 12px;
    font-weight: 400;
    color: #999;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 10px
}

.seatlayout .seatlayoutbox .seatarrangement {
    padding: 10px 0 30px;
    width: 100%;
    float: left
}

.seatlayout .seatlayoutbox .seatarrangement ul {
    margin: 2px 0;
    width: 95%;
    float: left
}

.tableview {
    float: left;
    width: 25%
}

.rightview {
    float: right;
    width: 75%
}

.tableview>div {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #cfc1c1;
    color: #000
}

.tableview>div:last-child {
    border-bottom: none
}

.seatlayout .seatlayoutbox .seatarrangement ul li {
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    background-color: #f9f9f9;
    color: #4a4a4a;
    padding: 0 2px;
    width: 30px;
    height: 30px;
    line-height: 28px;
    margin: 2px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #b7b7b7;
    display: inline-block
}

.seatlayout .seatlayoutbox .seatarrangement .rowlatter {
    width: 5%;
    float: left;
    padding: 8px 0
}

.seatlayout .seatlayoutbox .seatarrangement .rowlatter h4 {
    font-weight: 400;
    font-size: 14px;
    color: #b3b3b3;
    background-color: transparent;
    padding: 8px 0
}

.phead {
    margin-bottom: 30px;
    text-align: center
}

.promocode {
    display: flex;
    flex-direction: row;
    float: right
}

.promocode>div {
    background-color: #f1f1f1;
    margin: 10px;
    padding: 20px;
    font-size: 20px
}

@media only screen and (min-width:1400px) {
    .gt__user-sec {
        padding: 115px 30px 70px !important
    }
    .categorysec.gt__user-sec.categorysec3{
        padding: 40px 30px 400px !important;
    }
    .ticketsec.gt__user-sec {
        padding: 20px 30px !important
    }
    .gt__user-sec.proceedbtnsec2{
        padding: 85px 30px 70px !important;
    }
    .bottomfixd.gt__user-sec.proceedbtnsec2, .gt__space-around{
        padding: 0 30px !important;
    }
}

@media only screen and (min-width:1700px) {
    .gt__user-sec {
        padding: 115px 30px 70px !important
    }

    .tickets .details {
        width: 850px
    }

    .tickets .details .info .text>div .__seats strong {
        display: inline-block;
        font-size: 18px;
        width: auto;
        vertical-align: top
    }

    .tickets .details .info .text .inner-text>span {
        font-size: 16px
    }

    .tickets .details .info .text>div p {
        font-size: 17px;
        line-height: 24px
    }

    .tickets .details .info .text>div h1 {
        font-size: 22px
    }

    .booking-details p,
    .booking-details p span {
        font-size: 14px
    }
}

@media only screen and (min-width:1025px) {
    .seatlayout .seatlayoutbox .seatarrangement ul li:hover {
        background-color: #648fbb;
        color: #fff
    }

    .event_ticket_info {
        width: 90% !important
    }
}

.seatlayout .seatlayoutbox .seatarrangement ul li.selected {
    background-color: #648fbb;
    color: #fff
}

.seatlayout .seatlayoutbox .seatarrangement ul li.blocked {
    background-color: #e4e4e4;
    color: #fff;
    cursor: default;
    border: transparent
}

.seatlayout .seatlayoutbox .seatarrangement ul li.blank,
.seatlayout .seatlayoutbox .seatarrangement ul li.blank:hover {
    background-color: transparent;
    border-color: transparent
}

.bottomfixd {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    height: 86px;
    width: 100%;
    background-color: #fff;
    text-align: center
}

.bottomfixd .seatinfo,
.event_common_block p {
    padding: 5px 0
}

.bottomfixd .seatinfo ul li {
    display: inline-block;
    padding: 2px 10px
}

.bottomfixd .seatinfo ul li .infobox {
    width: 16px;
    height: 16px;
    border: 1px solid #b7b7b7;
    border-radius: 2px;
    float: left;
    background-color: #648fbb
}

.bottomfixd .seatinfo ul li .infobox1 {
    border-color: #eee;
    background-color: #e69e0d
}

.bottomfixd .seatinfo ul li .infobox4 {
    border-color: #eee;
    background-color: #f4e214
}

.bottomfixd .seatinfo ul li .infobox2 {
    background-color: #fff
}

.bottomfixd .seatinfo ul li h4 {
    font-size: 14px;
    font-weight: 300;
    color: #666;
    float: left;
    padding-left: 8px
}

.slider .clientsay {
    width: 100%;
    float: left;
    padding-top: 20px
}

.clientsay .imgpart {
    float: left;
    margin-left: 60px
}

.slick-next::before,
.slick-prev::before {
    font-size: 40px !important
}

.slick-next,
.slick-prev {
    top: 60% !important
}

.clientsay .content {
    float: left;
    width: 48%;
    text-align: left;
    padding-left: 44px;
    padding-top: 28px
}

.App,
.containers1,
.footer-container,
.proceedbtnsec,
.slick-slide,
.ticketsec {
    text-align: center
}

.clientsay .content h3 {
    color: #136995;
    font-size: 15px;
    font-weight: 500;
    padding: 19px 0 0 46px;
    background-repeat: no-repeat;
    background-position: 0 50%
}

.clientsay .content p {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    padding: 19px 51px 73px 0
}

.clientsay .content h4 {
    color: #136995;
    font-size: 18px;
    font-weight: 600
}

.clientsay .content h4 span {
    display: block;
    color: #646767;
    font-size: 14px;
    font-weight: 400
}

.clientsay .content h4::before {
    content: "";
    display: block;
    width: 56px;
    height: 3px;
    background-color: #479ede;
    margin-top: 15px;
    margin-left: -75px;
    position: absolute
}

#react-root {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: darken(white, 20%)
}

#app {
    padding: 1rem
}

.sample {
    margin-left: auto;
    margin-right: auto;
    width: 400px
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: Helvetica, Arial, san-serif
}

.slick-slide {
    height: 80vh;
    color: #fff;
    font-size: 20px;
    display: table !important
}

.footer-container {
    height: 100px;
    position: relative;
    font-size: 20px
}

.containers1,
.example-enter,
.example-leave {
    position: absolute;
    top: 0;
    right: 0;
    left: 0
}

.footer-container div {
    padding: 2em
}

.example-enter {
    opacity: .01
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity .5s ease-in
}

.example-leave {
    opacity: 1
}

.example-leave.example-leave-active {
    opacity: .01;
    transition: opacity .5s ease-in
}

.containers1 {
    margin-top: 0;
    padding: 10px 50px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    background: rgba(0, 0, 0, .7);
    opacity: 1;
    width: 100%;
    height: 100%;
    z-index: 999
}

.containers1 .section {
    width: 980px;
    margin: 0 auto;
    padding: 10px;
    position: fixed;
    top: 114px
}

.containers1 .search input {
    width: 100%;
    padding: 10px;
    border: .5px solid #424649;
    outline: 0
}

.containers1 .citys h2 {
    margin: 20px 0;
    font-size: 15px;
    color: #fff
}

.containers1 .citys .Second-row,
.containers1 .citys ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer
}

.citysize {
    margin-top: 50px
}

.containers1 .citys ul li {
    list-style: none;
    padding: 0 10px;
    width: 25%
}

.containers1 .citys ul li img {
    height: 61px;
    width: 61px
}

.containers1 #btn {
    background-color: transparent;
    color: red;
    border: none;
    outline: 0;
    font-size: 20px;
    margin: 0 0 10px;
    cursor: pointer
}

.event_common_block h3 {
    margin-top: 15px;
    text-transform: uppercase !important;
}

#Queensland,
#Tasmania,
#Victoria {
    margin-top: -20px;
}

.videoblog {
    height: 490px !important;
    position: relative
}

.videoblog .videospan {
    position: absolute;
    top: 50%;
    text-align: center;
    left: 0;
    right: 0;
    font-size: 50px;
    font-weight: 600;
    transform: translateY(-50%)
}

.event_common_block p.cdateadd {
    background-repeat: no-repeat;
    background-position: 0 5px;
    padding-left: 26px;
    margin: 5px 0
}

.event_common_block p.calender_date {
    background-image: url("../images/cal-date.svg")
}

.event_common_block p.address {
    background-image: url("../images/address.svg")
}

.gt__payment-logo img {
    margin: 0 4px
}

.categorysec2 .categorybox .categoryinfo .addbtn p {
    font-size: 20px;
    font-weight: 400;
    color: #222;
    margin: 0;
    padding: 0 12px;
    position: relative;
    float: left
}

.categorysec3 {
    background-image: url("../images/main-bg.jpg")
}

.categorysec2 .categorybox .categoryinfo .addbtn .minusbtn {
    background-image: url("../images/minus.svg");
    background-repeat: no-repeat;
    display: block;
    width: 25px;
    height: 25px;
    left: 0;
    top: -2px;
    border-radius: 100px;
    border: 1px solid #f05f23;
    background-position: 50% 50%;
    cursor: pointer;
    float: left
}

.categorysec2 .categorybox .categoryinfo .addbtn .plusbtn {
    background-image: url("../images/plus.svg");
    background-repeat: no-repeat;
    display: block;
    width: 25px;
    height: 25px;
    right: 0;
    top: -2px;
    border-radius: 100px;
    border: 1px solid #f05f23;
    background-position: 50% 50%;
    cursor: pointer;
    float: left
}

.proceedbtn2 {
    width: 70%;
    margin: 0 auto;
    display: inline-block
}

.proceedbtn10 {
    margin: 0 auto;
    display: inline-block
}

.proceedbtn2 h3 {
    float: left;
    font-size: 21px;
    font-weight: 400;
    color: #222;
    padding: 4px 0
}

.proceedbtn2 h3 span {
    display: block;
    font-size: 20px;
    color: #777;
    font-weight: 400
}

.proceedbtnsec .proceedbtn10 button,
.proceedbtnsec .proceedbtn2 button {
    width: 132px;
    float: right;
    background-color: #f05f23
}

.ticketsec.gt__user-sec {
    padding: 20px 30px;
}

.ticketsec {
    background-color: #142d80;
    color: #fff
}

.ticketsec .ticketinfo {
    position: relative;
    width: 625px;
    margin: 0 auto
}

.ticketsec .ticketinfo img {
    float: left;
    padding: 13px 0;
    cursor: pointer
}

.ctextinfo,
.ticketsec3 {
    display: inline-block;
    width: 100%
}

.auditorium_img {
    padding: 0 130px;
    width: 70%;
    margin-left: 200px
}

.ticketsec h2 {
    color: #fff;
    font-weight: 500;
    font-size: 20px
}

.ticketsec p span {
    padding-left: 10px;
    margin-left: 10px;
    position: relative
}

.ticketsec p span::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 22px;
    background-color: #fff;
    left: 0;
    top: -2px
}

.categorysec.gt__bg-image,
.ticketdetailsec {
    background-size: cover;
    background-position: 0 50%
}

.categorysec.gt__user-sec {
    padding: 40px 30px 400px
}

.categorysec .categorybox {
    max-width: 614px;
    margin: 0 auto
}

.categorysec .categorybox h2 {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #fff !important;
    animation: .5s ease-in-out fadeInDown;
    padding-top: 0;
    padding-bottom: 0
}

.categorysec .categorybox .categoryinfo {
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    display: inline-block;
    margin-top: 20px
}

.categorysec .categorybox .categoryinfo h3 {
    font-size: 18px;
    font-weight: 400;
    color: #222;
    float: left
}

.categorysec .categorybox .categoryinfo h3 span {
    font-size: 16px;
    display: block;
    color: #f05f23
}

.categorysec .categorybox .categoryinfo .addbtn {
    float: right;
    padding: 5px 0
}

.categorysec .categorybox .categoryinfo .addbtn button {
    cursor: pointer;
    background-color: #fff;
    padding: 5px 23px;
    color: #f05f23;
    border: 2px solid #f05f23;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px
}

.proceedbtnsec {
    background-color: #fff;
    padding: 20px 30px
}

.proceedbtnsec .proceedbtn button {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    width: 140px;
    height: 40px;
    border: none
}

.ticketsec3 .ticketinfo::before {
    top: -3px
}

.ticketdetailsec .ticketboxs {
    padding: 0 150px;
    width: 100%;
    display: inline-block
}

.ticketdetailsec .ticketboxs .opationbox {
    width: 58%;
    float: left;
    margin-right: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px
}

.ticketdetailsec .ticketboxs .paymentbox {
    width: 39%;
    float: left;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px
}

.termsservice {
    padding: 70px 0 100px
}

.termsservice .cpoint li {
    margin: 10px 0
}

.termsservice ul.cpoint.point {
    padding-left: 50px
}

.termsservice .cpoint.point li:after {
    background-color: transparent;
    border: 1px solid #222;
    width: 6px;
    height: 6px
}

.ctextinfo h4 {
    font-weight: 600;
    font-size: 19px;
    line-height: 30px;
    color: #222;
    padding: 15px 0;
    display: inline-block;
    width: 100%
}

.ctextinfo h4 span {
    font-weight: 400;
    font-size: 18px
}

.mpoint {
    text-transform: inherit !important
}

.cls-1 {
    fill: #ffc541
}

.cls-2 {
    fill: #4e4066
}

.cls-3 {
    fill: #6f5b92
}

.cls-4 {
    fill: #f78d5e
}

.cls-5 {
    fill: #fa976c
}

.cls-6,
.cls-7,
.cls-8 {
    fill: #b65c32
}

.cls-10,
.cls-6 {
    opacity: .6
}

.cls-7 {
    opacity: .4
}

.cls-9 {
    fill: #f4b73b
}

.cls-11 {
    fill: #f9c358
}

.cls-12 {
    fill: #9b462c
}

.cls-13 {
    fill: #aa512e
}

.cls-14 {
    fill: #7d6aa5
}

.wheel {
    animation: 6s infinite wheel-rotate;
    transform-origin: center;
    transform-box: fill-box
}

.clock-hand-1,
.clock-hand-2 {
    transform-origin: bottom;
    transform-box: fill-box
}

@keyframes wheel-rotate {
    50% {
        transform: rotate(360deg);
        animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53)
    }

    100% {
        transform: rotate(960deg)
    }
}

.clock-hand-1 {
    animation: 3s linear infinite clock-rotate
}

.clock-hand-2 {
    animation: 6s linear infinite clock-rotate
}

#ou,
#pillow,
#stripe,
#umbrella,
.circle {
    transform-origin: center
}

@keyframes clock-rotate {
    100% {
        transform: rotate(360deg)
    }
}

#box-top {
    animation: 2s linear infinite box-top-anim;
    transform-origin: right top;
    transform-box: fill-box
}

@keyframes box-top-anim {
    50% {
        transform: rotate(-5deg)
    }
}

#umbrella {
    animation: 6s linear infinite umbrella-anim;
    transform-box: fill-box
}

@keyframes umbrella-anim {
    25% {
        transform: translateY(10px) rotate(5deg)
    }

    75% {
        transform: rotate(-5deg)
    }
}

#cup {
    animation: 3s cubic-bezier(.455, .03, .515, .955) infinite cup-rotate;
    transform-origin: top left;
    transform-box: fill-box
}

@keyframes cup-rotate {
    50% {
        transform: rotate(-5deg)
    }
}

#pillow {
    animation: 3s linear infinite pillow-anim;
    transform-box: fill-box
}

@keyframes pillow-anim {
    25% {
        transform: rotate(10deg) translateY(5px)
    }

    75% {
        transform: rotate(-10deg)
    }
}

#stripe {
    animation: 3s linear infinite stripe-anim;
    transform-box: fill-box
}

@keyframes stripe-anim {
    25% {
        transform: translate(10px, 0) rotate(-10deg)
    }

    75% {
        transform: translateX(10px)
    }
}

#bike {
    animation: 6s infinite bike-anim
}

@keyframes bike-anim {
    0% {
        transform: translateX(-1300px)
    }

    50% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715)
    }

    100% {
        transform: translateX(1300px)
    }
}

#rucksack {
    animation: 3s linear infinite ruck-anim;
    transform-origin: top;
    transform-box: fill-box
}

@keyframes ruck-anim {
    50% {
        transform: rotate(5deg)
    }
}

.circle {
    animation: infinite circle-anim;
    transform-box: fill-box;
    perspective: 0px
}

.circle.c1,
.circle.c5 {
    animation-duration: 2s
}

.circle.c2,
.circle.c6 {
    animation-duration: 3s
}

.circle.c3,
.circle.c4 {
    animation-duration: 1s
}

.four.a,
.four.b {
    animation-duration: 3s;
    transform-box: fill-box
}

@keyframes circle-anim {
    50% {
        transform: scale(.2) rotateX(360deg) rotateY(360deg)
    }
}

#ou,
.four {
    animation: cubic-bezier(.39, .575, .565, 1) infinite four-anim
}

.four.a {
    transform-origin: bottom left
}

.four.b {
    transform-origin: bottom right
}

#ou {
    animation-duration: 6s;
    transform-box: fill-box
}

@keyframes four-anim {
    50% {
        transform: scale(.98)
    }
}

.App {
    display: flex;
    flex-flow: column;
    align-items: center
}

.Movies {
    margin-bottom: 24px
}

.Movies select {
    appearance: none;
    background: #fff;
    font-size: 14px;
    margin-left: 12px;
    border-radius: 4px;
    padding: 6px 24px
}

.ShowCase {
    margin: 0 0 48px;
    padding: 12px;
    display: flex;
    justify-content: center;
    background: #3b3b3b;
    border-radius: 4px;
    color: #7e7e7e
}

.ShowCase li {
    margin: 0 12px
}

.Cinema {
    margin-bottom: 18px;
    perspective: 400px;
    display: grid;
    place-items: center;
    grid-gap: 24px
}

.Cinema .screen {
    height: 70px;
    background: #fff;
    width: 100%;
    transform: rotateX(-30deg) scale(1.1);
    box-shadow: 0 3px 10px 2px
}

.Cinema .seats {
    display: grid;
    grid-gap: 6px;
    grid-template-columns: repeat(8, min-content);
    align-items: center
}

.Cinema .seats .seat:nth-of-type(8n+2),
.Cinema .seats .seat:nth-of-type(8n+6) {
    margin-right: 12px
}

.Cinema .seats .seat:not(.occupied):focus,
.Cinema .seats .seat:not(.occupied):hover {
    cursor: pointer;
    background: #c1eac5;
    transform: scale(1.2)
}

.Cinema .seats .seat.selected::after {
    content: '';
    position: absolute;
    top: 0;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: 0 0;
    border: 1px solid #7bc47f;
    animation: .8s show-off;
    visibility: hidden
}

@keyframes show-off {
    0% {
        transform: scale(1);
        opacity: 1;
        visibility: visible
    }

    100% {
        transform: scale(3);
        opacity: 0
    }
}

.seat {
    display: inline-block;
    background: #626262;
    width: 16px;
    height: 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: transform .3s ease-in-out;
    position: relative;
    top: 1px
}

.seat.selected {
    background: #7bc47f !important
}

.seat.occupied {
    background: #db0f0f !important
}

.occupied {
    background: #e69e0d !important
}

.inprogress {
    background: #f4e214 !important
}

.invalid-feedback {
    color: red
}

.info .count,
.info .total {
    color: #7bc47f
}

@media (max-width:1250px) {
    .gt__footer-rightwrap .gt__footer-payment {
        max-width: 48%;
        flex: 0 0 48%;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        margin: 7px 0 0
    }

    .containers1 .section {
        top: 100px
    }

    .containers1 {
        padding: 0
    }

    .gt__footer-rightwrap .gt__footer-menu {
        max-width: 26%;
        flex: 0 0 26%
    }

    .gt__call-sec .gt__number-sec a,
    .gt__call-sec h4 {
        font-size: 20px
    }

    .gt__call-sec {
        padding: 10px 0
    }

    .gt__tickets-wrap .gt__tickets-content {
        max-width: 60%
    }

    .slick-slider {
        padding-top: 70px !important
    }

    .gt__user-sec input {
        width: 100% !important
    }

    .categorysec3 {
        padding-bottom: 0 !important
    }
}

@media (max-width:1199px) {
    .gt__payment-logo img {
        width: 45px;
        height: 45px
    }

    .gt__footer-paymentinner {
        padding: 30px 25px
    }

    .gt__footer-right h3 {
        font-size: 16px
    }

    .gt__footer-paymentinner .gt__title {
        max-width: 170px
    }

    .gt__bootom-title {
        max-width: 127px
    }

    .gt__footer-rightwrap .gt__footer-payment {
        max-width: 44%;
        flex: 0 0 44%
    }

    .gt__footer-rightwrap .gt__footer-menu {
        max-width: 29%;
        flex: 0 0 28%
    }

    .gt__user-wrap {
        max-width: 60%
    }

    .iKHtID {
        width: 60%
    }

    .seatlayout .seatlayoutbox {
        padding: 0 50px
    }

    .gt__header-menu ul li,
    span.menu-close {
        text-align: center;
        display: inline-block
    }

    span.menu-close {
        position: absolute;
        color: #fff;
        font-size: 14px;
        border: 1px solid #fff;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        line-height: 18px;
        right: 10px;
        top: 10px
    }

    .mobile-toggle.hidden--md {
        display: block;
        position: relative
    }

    .mobile-toggle .mobile-toggle__inner {
        width: 22px;
        height: 20px;
        position: absolute;
        right: 0;
        top: 5px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        cursor: pointer;
        z-index: 99;
        display: block
    }

    .mobile-toggle .mobile-toggle__inner span {
        background-color: #3a3d49;
        height: 2px;
        width: 100%;
        display: block;
        margin-bottom: 4px
    }

    .gt__header-menu {
        display: none;
        transition: .2s ease-in-out
    }

    .gt__header-menu.menu_open {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background: #000;
        float: left;
        width: 100%;
        padding: 40px 0 0;
        min-width: 280px;
        -webkit-box-shadow: 6px 0 12px 0 #000;
        box-shadow: 6px 0 12px 0 #000;
        z-index: 9999;
        overflow-y: auto;
        transition: .2s ease-in-out
    }

    .gt__header-right {
        justify-content: flex-end
    }

    .justify-content-between {
        justify-content: space-between !important
    }

    .gt__header-menu ul li a {
        font-weight: 400
    }

    .gt__header-menu ul li {
        padding: 15px 10px;
        border-bottom: 1px solid
    }

    .container {
        width: 940px
    }

    .eventinfo ul {
        display: inline-block;
        float: left
    }

    .eventinfo ul li {
        width: 50% !important
    }

    .eventinfo ul li:nth-child(2n+0) {
        border-right: none
    }

    .eventinfo ul li:nth-child(3n+0) {
        padding-left: 0
    }

    .eventabout .eventtext {
        margin-left: 290px
    }

    .eventabout .eventtext .interested .cbtn {
        top: 40px
    }

    .tickets ul {
        padding: 0 30px;
        width: 100%
    }

    .booking-details,
    .tickets .details {
        width: 100%
    }

    .gt__btn {
        padding: 10px 25px
    }

    .info .cinfobox {
        width: 50%
    }
}

@media (max-width:991px) {
    .gt__header-wrap .gt__header-left a img {
        max-width: 85%;
        margin-top: 7px
    }

    .tableview {
        float: left;
        width: 45%
    }

    .rightview {
        float: right;
        width: 55%
    }

    .seatlayout {
        padding: 70px 40px 100px !important
    }

    .containers1 .section {
        top: 165px;
        width: 100%
    }

    .iKHtID,
    .notetext,
    .share_offer.box_split_section .ng-binding,
    .ticketsec .ticketinfo {
        width: 100%
    }

    .videoblog .videospan {
        font-size: 34px
    }

    .sponsorbox {
        padding: 0 20px;
        text-align: left
    }

    .gt__space-around,
    header.gt__fixed-heder .gt__header-wrap {
        padding: 0 15px
    }

    .gt__header-wrap .gt__header-left {
        max-width: 20%;
        flex: 0 0 20%
    }

    .slick-slider {
        padding-top: 125px !important
    }

    .slick-next,
    .slick-prev {
        top: 70% !important
    }

    .gt__header-wrap .gt__header-right {
        max-width: 80%;
        flex: 0 0 80%
    }

    .gt__header-wrap .gt__header-left a.active {
        float: left
    }

    .gt__header-menu ul li {
        padding: 15px 8px
    }

    .gt__header-menu {
        padding-left: 10px;
        padding-right: 20px
    }

    header.gt__fixed-heder {
        top: -5px
    }

    .gt__location {
        position: fixed;
        top: 0;
        width: 100%;
        background-color: #fff;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        border-bottom: 1px solid #ddd;
        z-index: 22
    }

    .gt__header-wrap {
        padding: 5px 15px;
        margin-top: 35px
    }

    .gt__header-wrap.inner_header {
        margin-top: 5px
    }

    .gt__header-socialsec,
    .gt__search-block {
        order: 0
    }

    .search_mobile {
        float: left;
        padding: 25px 0 0 95px;
        display: block !important
    }

    .gt__user-details,
    .mobile-toggle.hidden--md {
        order: 1
    }

    .gt__header-socialsec>div {
        padding: 5px
    }

    .gt__full-width {
        max-width: 100% !important;
        flex: 0 0 100% !important
    }

    .gt__footer-wrap .gt__footer-right {
        border-radius: 0;
        padding: 0
    }

    .gt__footer-rightwrap {
        padding: 30px 15px 0
    }

    .gt__footer-rightwrap .gt__footer-payment {
        justify-content: flex-start;
        margin: 15px 0 35px
    }

    .gt__footer-rightwrap>div:nth-child(2) {
        padding: 15px 0;
        margin: 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, .22);
        border-top: 1px solid rgba(255, 255, 255, .22)
    }

    .gt__footer-menu ul {
        margin: 10px 0 0
    }

    .gt__footer-wrap .gt__footer-left {
        padding: 15px 15px 20px
    }

    .gt__call-sec .gt__number-sec a,
    .gt__call-sec h4 {
        font-size: 18px
    }

    .gt__call-sec .gt__number-sec img {
        max-width: 25px
    }

    .gt__call-sec {
        padding: 10px 15px
    }

    .gt__call-sec .gt__number-sec {
        padding-left: 0
    }

    .gt__footer-logo img {
        max-width: 50%
    }

    .gt__footer-social {
        padding: 15px 0 0
    }

    .gt__user-sec {
        padding: 50px 15px 70px
    }

    .gt__user-wrap {
        max-width: 100%;
        padding: 55px 20px 20px
    }

    .gt__form-grp.gt__uname>div {
        max-width: 100%;
        flex: 0 0 100%
    }

    .gt__fname-block,
    .gt__user-sec .gt__form-grp {
        margin-bottom: 15px
    }

    .gt__login-googlefb>div a span {
        font-size: 14px
    }

    .gt__login-googlefb .gt__google-block {
        margin-bottom: 10px
    }

    .gt__user-sec .gt__user-icn {
        height: 60px;
        width: 60px;
        line-height: 90px;
        top: -4%
    }

    .gt__user-sec .gt__user-icn img {
        max-width: 30px
    }

    .gt__user-sec input {
        padding: 8px 15px
    }

    .gt__user-sec h1 {
        padding-bottom: 55px
    }

    .gt__events-wrap .gt__events-block {
        padding: 15px;
        max-width: 33.33%;
        flex: 0 0 33.33%
    }

    .container {
        width: 740px
    }

    .seatlayout .seatlayoutbox {
        padding: 0 0 0 20px
    }

    .details-banner.hero-area.bg_img.seat-plan-banner .gt__header-left img {
        width: 15% !important
    }

    .afterlogin {
        top: 45px
    }
}

@media (max-width:767px) {
    .rightview {
        width: 100%;
        margin-top: 30px
    }

    .eventabout .eventimgbox,
    .gt__title-wrap h2,
    .tableview {
        width: 100%
    }

    .tickets .details .info .text {
        width: 70%
    }

    .sponsorbox .desc {
        margin: 30px 0 0 120px;
        text-align: left
    }

    .slick-slide div .videoblog {
        height: 427px !important
    }

    .slick-slide div .videoblog video {
        height: 427px !important;
        object-fit: cover
    }

    .containers1 .section {
        top: 86px
    }

    .videoblog .videospan {
        font-size: 22px;
        padding: 0 20px
    }

    .gt__header-menu {
        padding-left: 10px;
        padding-right: 14px
    }

    .gt__events-wrap .gt__events-block {
        padding: 10px 7px;
        max-width: 50%;
        flex: 0 0 50%
    }

    .gt__events-date span {
        font-size: 11px
    }

    .gt__events-content .gt__btn {
        font-size: 12px;
        padding: 5px 10px
    }

    .organizers {
        width: 100%;
        padding-top: 25px;
        float: left
    }

    .gt__title-wrap a {
        margin: 15px 0 0
    }

    .gt__title-wrap {
        padding: 0 0 20px
    }

    .gt__events-wrap {
        margin: 0 -7px
    }

    .gt__nearevent-sec,
    .gt__upcomming-sec {
        padding: 40px 0
    }

    .gt__tickets-wrap {
        max-width: 100%;
        padding: 0 15px
    }

    h1,
    h2 {
        font-size: 20px
    }

    .container {
        width: auto;
        padding: 0 15px
    }

    .eventinfo ul li {
        padding: 0;
        border-right: none;
        width: 100% !important;
        margin-bottom: 0
    }

    .eventinfo ul li .desc p br {
        display: none
    }

    .eventinfo ul li .time {
        margin-top: 0
    }

    .eventabout {
        padding: 50px 0
    }

    .eventabout .eventtext {
        margin-left: 0;
        margin-top: 30px;
        float: left;
        width: 100%
    }

    .seatlayout {
        padding: 70px 40px 100px !important
    }

    .seatlayout .seatlayoutbox {
        padding: 0 0 0 18px
    }

    .details-banner.hero-area.bg_img.seat-plan-banner .gt__header-left img {
        width: 15% !important
    }

    .slick-slider {
        padding-top: 112px !important
    }

    header.gt__fixed-heder .gt__header-logo img {
        max-width: 70%
    }
}

@media only screen and (max-width:747px) {

    .afterlogin,
    .afterlogin::before {
        position: absolute;
        right: inherit
    }

    .gt__header-menu ul li:last-child {
        border: none
    }

    .afterlogin {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        min-width: 140px;
        z-index: 22;
        top: 60px;
        padding: 10px;
        left: 0
    }

    .afterlogin::before {
        content: "";
        top: -10px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #e7e8e6;
        left: 10px
    }

    .afterlogin li a {
        padding: 5px 6px;
        font-size: 14px;
        margin: 0 !important
    }

    .gt__events-content .gt__btn {
        margin-bottom: 10px
    }

    .gt__header-wrap .gt__header-left {
        flex: 0 0 100%
    }

    .slick-slider {
        padding-top: 105px !important
    }

    a.gt__header-logo {
        float: left;
        margin-top: 9px
    }

    .gt__header-wrap .gt__header-left,
    .gt__header-wrap .gt__header-right {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center
    }

    .gt__header-socialsec,
    .gt__search-block.gt__img-middle.search_bar {
        width: 100%;
        margin-top: 5px
    }

    .gt__header-menu ul li {
        padding: 10px !important;
        display: inline-block;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid
    }

    .gt__header-right .gt__header-menu.submenu {
        width: 100%;
        padding-left: 0
    }

    .gt__header-right .gt__header-menu.submenu .d-flex {
        display: block
    }

    .gt__search-block.gt__img-middle.search_bar.submenu {
        width: 45%
    }

    .gt__user-details.gt__img-middlesubmenu {
        position: relative;
        width: 100%;
        float: left
    }

    .gt__user-details.gt__img-middlesubmenu a {
        float: left;
        margin-top: 20px;
        margin-bottom: 0
    }

    header.gt__fixed-heder .gt__header-logo img {
        max-width: 85%
    }

    .gt__header-left {
        padding-top: 7px
    }

    ul.d-flex.d-flex-wrap.align-items-center {
        margin-bottom: 5px
    }

    #mobilemenu {
        float: right;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: #136995;
        border-radius: 6px;
        background-image: url(../images/menu-icon.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #136995;
        -webkit-border: 1px solid #136995;
        -moz-border: 1px solid #136995;
        margin: 30px 0
    }

    #mobilemenu.closebtn {
        background-image: url(../images/close-icon.png)
    }
}

@media only screen and (max-width:639px) {

    .afterlogin,
    .afterlogin::before {
        position: absolute;
        right: inherit
    }

    .organizername {
        text-align: center;
        font-size: 16px
    }

    .gt__header-menu ul li:last-child {
        border: none
    }

    .afterlogin {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        min-width: 140px;
        z-index: 22;
        top: 60px;
        padding: 10px;
        left: 0
    }

    .afterlogin::before {
        content: "";
        top: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #e7e8e6;
        left: 10px
    }

    .afterlogin li a {
        padding: 5px 6px;
        font-size: 14px;
        margin: 0 !important
    }

    .gt__events-content .gt__btn {
        margin-bottom: 10px
    }

    .gt__header-wrap .gt__header-left {
        flex: 0 0 100%
    }

    .slick-slider {
        padding-top: 105px !important
    }

    a.gt__header-logo {
        float: left;
        margin-top: 0;
        text-align: left
    }

    .gt__header-wrap .gt__header-left,
    .gt__header-wrap .gt__header-right {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center
    }

    .gt__header-socialsec,
    .gt__search-block.gt__img-middle.search_bar {
        width: 100%;
        margin-top: 5px
    }

    .gt__header-menu ul li {
        padding: 10px !important;
        display: inline-block;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid
    }

    .gt__header-right .gt__header-menu.submenu {
        width: 100%;
        padding-left: 0
    }

    .gt__header-right .gt__header-menu.submenu .d-flex {
        display: block
    }

    .gt__search-block.gt__img-middle.search_bar.submenu {
        width: 60%
    }

    .gt__user-details.gt__img-middlesubmenu {
        position: relative;
        width: 100%;
        float: left
    }

    .gt__user-details.gt__img-middlesubmenu a {
        float: left;
        margin-top: 20px;
        margin-bottom: 0
    }

    header.gt__fixed-heder .gt__header-logo img {
        max-width: 74%
    }

    .gt__header-left {
        padding-top: 7px
    }

    ul.d-flex.d-flex-wrap.align-items-center {
        margin-bottom: 5px
    }

    #mobilemenu {
        float: right;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: #136995;
        border-radius: 6px;
        background-image: url(../images/menu-icon.png);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border: 1px solid #136995;
        -webkit-border: 1px solid #136995;
        -moz-border: 1px solid #136995;
        margin: 18px 0
    }

    #mobilemenu.closebtn {
        background-image: url(../images/close-icon.png)
    }

    .eventinfo .eventtitle .cbtn {
        position: relative;
        top: 0;
        margin-top: 20px
    }

    .eventinfo .eventtitle p {
        font-size: 18px
    }

    .ctitle {
        font-size: 22px
    }

    .sponsorbox .desc {
        margin-left: 120px
    }

    .tableview,
    .tickets .details .bcode {
        width: 100%
    }

    .rightview {
        width: 100%;
        margin-top: 30px;
        float: left
    }

    .seatlayout .seatlayoutbox {
        padding: 0 10px 0 0
    }

    .tickets .details .info {
        width: 100%;
        border-right: none;
        border-top: 1px dashed #e3e3e3
    }

    .tickets .details::before {
        right: -10px;
        top: 70px
    }

    .tickets .details::after {
        right: inherit;
        left: -10px;
        top: 70px
    }

    .tickets .details .info .__img {
        width: 30%
    }

    .tickets .details .info .text {
        width: 70%;
        padding: 25px 0 0 10px
    }

    .movie-facility .col-lg-4,
    .movie-facility .col-lg-8 {
        max-width: 100%;
        flex: 0 0 100%
    }

    .details-banner.hero-area.bg_img.seat-plan-banner .gt__header-left img {
        width: 35% !important
    }

    .checkout-widget {
        margin-bottom: 15px !important
    }

    .seatlayout .seatlayoutbox .seatarrangement {
        padding: 10px 0 80px
    }

    .info .cinfobox a {
        font-size: 13px;
        margin-top: 10px
    }

    .info .cinfobox h3 {
        font-size: 16px
    }

    .info .cinfobox p {
        line-height: 20px;
        margin-top: 10px;
        font-size: 13px
    }

    .gt__form-grp.gt__uname>div {
        margin: 10px 0
    }
}

@media only screen and (max-width:575px) {
    .containers1 .citys h2 {
        margin: 5px 0;
        font-size: 11px
    }

    .containers1 .citys ul li {
        padding: 0 5px
    }

    .slick-slide div .videoblog {
        height: 350px !important
    }

    .containers1 .section {
        top: 100px
    }

    .slick-slide div .videoblog video {
        height: 350px !important;
        object-fit: cover
    }
}

@media only screen and (max-width:479px) {

    .ticketsec.gt__user-sec {
        padding: 20px 30px !important;

    }

    .afterlogin,
    .afterlogin::before {
        position: absolute;
        right: inherit
    }

    .gt__user-sec {
        padding: 110px 30px 70px !important
    }

    .gt__nearevent-sec {
        padding: 40px 0 10px
    }

    .gt__footer-logo a.gt__header-logo {
        width: 175px
    }

    .gt__footer-logo img {
        max-width: 65%
    }

    .slick-slider {
        padding-top: 101px !important
    }

    .slide1 {
        height: 200px !important
    }

    .ctectinfo h5,
    .gt__search-block.gt__img-middle.search_bar.submenu,
    .info .cinfobox,
    .proceedbtn2,
    .tickets .details .info .__img,
    .tickets .details .info .text {
        width: 100%
    }

    .ctitle {
        font-size: 20px
    }

    #mobilemenu {
        margin-top: 16px
    }

    .gt__header-right .gt__header-menu.submenu {
        width: 100%;
        padding-left: 0
    }

    .gt__header-right .gt__header-menu.submenu ul {
        margin-bottom: 0
    }

    .booking-details,
    .gt__header-wrap .gt__header-left a {
        float: left
    }

    .gt__header-socialsec,
    .gt__search-block.gt__img-middle.search_bar {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px
    }

    a.mypro {
        margin: 0
    }

    .search_bar input {
        width: calc(100% - 25px)
    }

    .gt__header-menu ul li {
        padding: 10px 5px !important;
        display: inline-block;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid
    }

    .gt__header-menu ul li:last-child {
        border: none
    }

    .afterlogin {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
        min-width: 140px;
        z-index: 22;
        top: 60px;
        padding: 10px;
        left: 0
    }

    .afterlogin::before {
        content: "";
        top: -10px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid #e7e8e6;
        left: 10px
    }

    .eventabout .eventtext .interested .cbtn {
        position: relative;
        margin: 10px 0;
        top: 0
    }

    .shareevent {
        display: inline-block
    }

    .eventabout .eventtext .gt__social-img,
    .my_ticket p.bookingno {
        padding-left: 0
    }

    .__booking-id,
    .gt__call-sec .gt__number-sec a,
    .gt__call-sec h4,
    .time-section {
        font-size: 14px
    }

    .ticketsec h2 {
        font-size: 16px
    }

    .gt__header-wrap .gt__header-left,
    .gt__header-wrap .gt__header-right {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center
    }

    header .gt__header-logo .gt__header-logo {
        text-align: center
    }

    header .gt__header-logo img {
        max-width: 100%;
        width: 142px;
        float: left
    }

    .bottomfixd.gt__user-sec {
        padding: 0 15px !important;
    }

    .bottomfixd {
        height: 105px
    }

    .seatlayout {
        padding: 30px 15px !important
    }

    .iKHtID .fcfhSc {
        padding: 18px 25px
    }

    .iKHtID .kcqWmw {
        padding: 36px 25px 0
    }

    .iKHtID .hJcxfv {
        flex-wrap: wrap !important
    }

    .iKHtID .eWtCsq,
    .iKHtID .kukXfn {
        min-width: 100%
    }

    .countdown-wrapper,
    .eventinfo ul li {
        margin-top: 10px
    }

    .eventinfo ul li .icon {
        height: 45px
    }

    .eventinfo ul li .desc p {
        font-size: 14px;
        line-height: 20px
    }

    .checkout-widget h5,
    .eventinfo ul li .time {
        font-size: 20px !important
    }

    .checkout-contact-form .form-group input,
    .tickets .details .info .text>div .__seats strong,
    .tickets .details .info .text>div h1 {
        font-size: 13px !important
    }

    .custom-button.back-button {
        padding: 6px 15px;
        font-size: 12px !important
    }

    .checkout-contact-form .form-group span {
        margin-left: 0 !important
    }

    .booking-summery .title {
        font-size: 20px !important;
        padding-bottom: 20px !important;
        margin-bottom: 20px !important
    }

    .booking-summery ul li .subtitle,
    .total .__value {
        font-size: 16px !important
    }

    .booking-summery ul li .info {
        font-size: 12px !important
    }

    .proceed-area h6 {
        font-size: 14px !important
    }

    .tickets ul {
        padding: 0 20px
    }

    .my_ticket p {
        padding-left: 20px !important
    }

    .gt__user-sec .gt__form-block {
        padding-top: 15px
    }

    .gt__signin-wrap.gt__user-wrap.padding {
        padding: 20px !important
    }

    .gt__user-sec h1 {
        padding-bottom: 30px
    }

    .gt__user-sec h1.contacts {
        padding-top: 60px !important
    }

    .tickets .details .info .__img {
        padding: 0 55px
    }

    .tickets .details .bcode {
        padding: 20px
    }

    .tickets .details .info .text>div p {
        margin: 5px 0
    }

    .tickets .details .info .text .ticket-breakup {
        padding-top: 0
    }

    .tickets .details .info .text {
        padding: 10px 0 0
    }

    .tickets .details .info {
        padding-bottom: 0
    }

    .__qrcode img {
        max-width: 40%
    }

    .search_mobile {
        padding: 25px 0 0 55px !important
    }

    .slick-next,
    .slick-prev {
        top: 67% !important
    }

    .slick-next::before,
    .slick-prev::before {
        font-size: 25px !important
    }

    .slick-slide img {
        height: 100% !important
    }
}

.gt__header-left {
    text-align: center
}

.gt__header-left img {
    margin-left: inherit !important
}

.custom-button.back-button:hover {
    box-shadow: none
}

.slick-slider {
    padding-top: 78px
}

.gt__events-wrap .gt__events-block {
    max-width: 297px
}

.categorysec3 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding-bottom: 200px
}

.my_ticket:first-child p {
    padding-top: 200px
}

.booking-details p {
    padding-left: 0 !important
}

.gt__user-sec h1.contacts {
    padding-top: 87px
}

.gt__user-sec {
    padding: 85px 30px 70px
}
.gt__user-sec.gt__signin-sec1 {
    padding: 130px 30px 70px
}
.booking-summery ul li span.info {
    padding: 8px 0
}

.termsservice {
    padding-top: 135px
}

.slick-next,
.slick-prev {
    z-index: 9;
    width: 40px !important;
    height: 36px !important
}

.loader,
.loader1 {
    width: 80px;
    height: 80px;
    animation: 1s linear infinite spinner
}

.slick-prev:before {
    font-size: 40px
}

.slick-prev:before .slick-next:before {
    font-size: 40px !important
}

.slick-prev {
    left: 10px !important
}

.slick-next {
    right: 10px !important
}

.share_offer.box_split_section {
    padding-top: 88px
}

.loader {
    margin: 125px auto 20px !important;
    border: 20px solid #eaf0f6;
    border-radius: 50%;
    border-top: 20px solid #ff7a59
}

.slick-next::before,
.slick-prev::before {
    color: #d4c5c5 !important
}

.loader1 {
    border: 20px solid #eaf0f6;
    border-radius: 50%;
    border-top: 20px solid #ff7a59
}

@keyframes spinner {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}